export const DropDownItems = [
    {
      title: 'NATURE',
      path: '/nature',
      cName: 'dropdown-link',
    },
  
    {
      title: 'FLORALS',
      path: '/florals',
      cName: 'dropdown-link',
    },
    {
      title: 'WILD-LIFE',
      path: '/wildlife',
      cName: 'dropdown-link',
    },
  
    {
      title: 'MASTER-COPY',
      path: '/mastercopies',
      cName: 'dropdown-link',
    },
    {
      title: 'PORTRAITS',
      path: '/portraits',
      cName: 'dropdown-link',
    },
    {
      title: 'PET PORTRAIT ',
      path: '/petportraits',
      cName: 'dropdown-link',
    },
    {
      title: 'SOLD',
      path: '/sold',
      cName: 'dropdown-link',
    },
  ];
  
  // dropdown for contact service
  
  const ServiceOption = [
    {
      label: 'Commissions',
    },
  
    {
      label: 'Portrait',
    },
    {
      label: 'Workshop',
    },
    {
      label: 'Classes',
    },
    {
      label: 'General',
    },
  ];
  
  export default ServiceOption;